<template>
    <div class="login_container">
        <Header></Header>
        <div class="login_box">
            <div style="height: 50px;line-height: 50px;padding-left: 20px;border-bottom: 1px solid #d8dbe2;font-size: 14px">
                登录后台账号
            </div>
            <div class="login_form">
                <el-form label-width="50px" class="login_in" v-model="form">
                    <el-row>
                        <el-col :span="16" :offset="4">
                            <el-form-item  label="账号:">
                                <el-input type="text" prefix-icon="el-icon-user" v-model="form.user_name"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16" :offset="4">
                            <el-form-item label="密码:">
                                <el-input type="password" prefix-icon="el-icon-thumb"  v-model="form.password"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :offset="6" :span="4">
                            <el-button type="primary" @click="onSubmit">登录</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
    import Header from './components/header'
    import JsCookie from 'js-cookie'
    export default {
        name: "login",
        data: function () {
            return {
                form:{
                    user_name:"",
                    password:""
                }
            }
        },
        components:{Header},
        methods:{
            onSubmit:function () {
                this.$axios.post("/site/user/login",this.form).then((res)=>{
                    if (res.data.code == 1000){
                        this.$message.success("登录成功!");
                        JsCookie.set("token",res.data.data.token);
                        setTimeout(()=>{
                            this.$router.push("/admin/index");
                        },1000)
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
        },
        created() {

            console.log("aa".indexOf("2a"))
        }
    }
</script>

<style scoped>
    .login_container {
        background-color: #f5f8fa;
        height: 100vh;
    }
    .login_form{
        padding-top: 30px;
    }
    .login_box {
        width: 40%;
        max-width: 90%;
        height: 300px;
        background-color: #fff;
        border-radius: 3px;
        position: relative;
        left: 50%;
        top: 200px;
        transform: translate(-50%, -50%);
        border: 1px solid #d3e0e9;
    }
    .login_box .avatar_box {
        height: 130px;
        width: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
    }

    @media screen and (max-width: 768px) {
        .login_box{
            width: 100%;
        }
    }

</style>
