<template>
    <div class="main">
        <el-row type="flex">
            <el-col :span="10" :offset="2">
                <div class="logo">
                    <router-link to="/"><img style="height: 50px" src="@/assets/img/admin-logo.png" alt="logo"></router-link>
                </div>
            </el-col>
            <el-col :span="10" style="text-align: right">
                <div class="menu">
                    <!--<div><router-link to="/register">注册</router-link></div>-->
                    <!--<div><router-link to="/login">登录</router-link></div>-->
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "header"
    }
</script>

<style scoped>
    .main{
        height: 50px;
        border-color: #d3e0e9;
        border-bottom: 1px solid #d3e0e9;
        line-height: 50px;
        background-color: #ffffff;
    }
    .menu > div{
        float: right;
        margin-left: 25px;
    }
    .menu a{
        color: #777;
        font-size: 14px;
        text-decoration: none;
    }
    .menu a:hover{
        color: #080305;
    }
</style>
